import { initializeApp } from "firebase/app";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyCbHiteSA-H1p7iGcra31Q4CxZXJuYTjcU",
    authDomain: "ihaagfoto-1c69f.firebaseapp.com",
    databaseURL: "https://ihaagfoto-1c69f.firebaseio.com",
    projectId: "ihaagfoto-1c69f",
    storageBucket: "ihaagfoto-1c69f.appspot.com",
    messagingSenderId: "373193979638"
});

    // Firebase storage reference
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west1');

// connectStorageEmulator(storage, "127.0.0.1", 9199);
// connectFunctionsEmulator(functions, "localhost", 5001);