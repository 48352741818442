import {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingBag, faRemove} from '@fortawesome/free-solid-svg-icons';
import '../css/cart.css';

const Cart = (props) => {

    const [visible, setVisible] = useState(false);

    const isCartEmpty = props.items.length < 1;

    const handleSubmit = () => {

        // fetch("http://localhost:5001/ihaagfoto-1c69f/us-central1/createStripeCheckout", {
        fetch("https://europe-west1-ihaagfoto-1c69f.cloudfunctions.net/createStripeCheckout", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            mode: "cors",
            body: JSON.stringify(props.items)
        }).then(response => {
            window.location.href = response.headers.get("redirect");
        })
    }

    const getCartTotal = () => {
        return (props.items.map(i=> i.amount * i.price)
            .reduce((total, item) => total+item)/100).toFixed(2);
    }

    const getItemTotal= () => {

        return props.items.map(i => {
           return i.amount;
        }).reduce((partial, a) => partial + a);
    }

    return(
        <div id={"cart"} className={"popover-wrapper"} >
            <div className={"cart-icon-wrapper"} onClick={isCartEmpty ? null : () => setVisible(!visible)}>
                <FontAwesomeIcon style={isCartEmpty ? { height: "2rem", color: "grey" } :{ height: "2rem", color: "#1c1c1c" } } icon={faShoppingBag} />
                {!isCartEmpty && <div className={"cart-item-count"}>{getItemTotal()}</div>}
            </div>
            {visible && !isCartEmpty &&<div className={"popover-container"}>
                <div className="arrow-up"></div>
                <div className={"popover-content"}>
                    <div className={"cart-items"}>
                        {props.items.map(item => (
                            <div key={item.id} className={"cart-item"}>
                                <span onClick={() => props.handleRemove(item)} className={"cart-item-remove"}><FontAwesomeIcon style={{color:"rgb(255, 95, 0)"}} icon={faRemove} /></span>
                                <span className={"cart-item-name"}>{item.name}</span>
                                <div className={"cart-right"}>
                                    <div className={"cart-item-amount-wrapper"}>
                                        <button className={"cart-item-btn"} onClick={() => props.handleChange(item.id, -1)}>-</button>
                                        <span className={"cart-item-amount"}>{item.amount}</span>
                                        <button className={"cart-item-btn"} onClick={() => props.handleChange(item.id, 1)}>+</button>

                                    </div>

                                    <span className={"cart-item-price"}>{((item.price * item.amount)/100).toFixed(2)}</span>

                                </div>

                            </div>
                        ))}
                        <div className={"cart-items-total"}>Total: {getCartTotal()}</div>
                    </div>

                    <button className={"product-btn"} onClick={handleSubmit} id="checkout-button">Checkout</button>
                </div>
            </div>}
        </div>
    );
}

export default Cart;