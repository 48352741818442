import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useWindowSize} from "../hooks/useWindowSize";

const Carousel =(props) => {

  const [width] = useWindowSize();
  const  fillSet = () => {
    let set = [];
    let photoset = props.photoset;

    for(let i = 0; i< photoset.length;i++){

      set.push(
          <img className="slide-img" key={i}

               src={props.photoset[i]}
               alt={'image from portfolio'}
          />
      );
    }
    return set;
  }

  if(width < 768) {
    return(
        <div className={"carousel-container"}>
          {fillSet()}
        </div>
    );
  } else{

    const settings = {
      infinite: true,
      variableWidth: true,
      centerMode: true,
      adaptiveHeight: true
    }
    return(
        <Slider  {...settings}>
          {fillSet()}
        </Slider>
    );
  }

}
export default Carousel;
