import {NavLink} from "react-router-dom";
import React  from "react";
import * as PropTypes from "prop-types";
import '../css/navbar.css';

NavBar.propTypes = {className: PropTypes.func};

function NavBar(props) {

    return <div className={"navbar"}>
        <span className={"name-row"}>István Haág</span>
        <ul>
            <li><NavLink end to={"/"} className={({isActive}) => "nav-link " + (isActive ? "active" : "")}>Home</NavLink></li>

            { props.items.map(g => <li key={g.path} ><NavLink to={"/" + g.name}>{g.name}</NavLink></li>) }

            {props.isShopVisible ? <li><NavLink to={"/shop"} className={({isActive}) => "nav-link " + (isActive ? "active" : "")}>Shop</NavLink></li> : null}

        </ul>
    </div>;
}

export default NavBar;