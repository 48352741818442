import React from 'react';
import '../css/about.css';
import NavBar from "./NavBar";
import Footer from "./Footer";

function About (props) {
    return (
        <div>
            <NavBar isShopVisible={props.isShopVisible} items={props.navbar} />
            <div className="wrap">
                <div className="about-me-image">
                    <img src="../images/profile.jpg" alt="me"></img>
                </div>
                <div className="about-me-description">
                    <p>
                        Hungarian born, Australian raised photographer focusing on landscapes and glamour photography, but dabbling in fashion and urban themes from time to time.
                    </p>

                </div>

            </div>
            <Footer />
        </div>
    )
}
export default About;
