import React, { useEffect, useState} from 'react';
import Carousel from './Carousel.js';
import '../css/home.css';
import '../css/loading_animation.css'
import Footer from "./Footer";
import NavBar from "./NavBar";
import LoadingAnimation from "./LoadingAnimation";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {storage} from "../firebaseConfig";

function GalleryPage(props) {
    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [photoset, setPhotoset] = useState([]);

    const listRef = ref(storage, props.apiURL);

    useEffect(() => {
        listAll(listRef)
            .then(async (res) => {

                const imageURLs = await Promise.all(
                    res.items.map((item) => getDownloadURL(item))
                );
                setPhotoset(imageURLs);
                setIsLoaded(true);
            }).catch((error) => {
            setError(error);
        });
        setIsLoaded(true);
    },[ props.apiURL]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <div>
                <NavBar isShopVisible={props.isShopVisible} items={props.navbar} />
                <LoadingAnimation/>
                <Footer/>
            </div>
        )
    } else {

        return (
            <div>
                <NavBar isShopVisible={props.isShopVisible} items={props.navbar}/>
                <div className="full-width-carousel">
                    <Carousel photoset={photoset}/>
                </div>
                <Footer/>
            </div>

        );
    }
}

export default GalleryPage;
