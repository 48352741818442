import React from 'react';
import '../css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFlickr } from '@fortawesome/free-brands-svg-icons';
import {Link} from "react-router-dom";


const Footer = () => {
    return (
      <div className={"footer"}>
        <ul >
          <li>
            <a href="https://www.instagram.com/haag.i.g/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
          </li>
          <li>
            <a href="https://www.flickr.com/photos/haagistvan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFlickr} /></a>
          </li>
          <li>
            <a href="mailto:ihaagfoto@gmail.com" ><FontAwesomeIcon icon={faEnvelope} /></a>
          </li>
          <li>
            <Link to="/about" ><FontAwesomeIcon icon={faQuestionCircle} /></Link>
          </li>

        </ul>
      </div>
    )
}
export default Footer;
