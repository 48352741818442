import React, {useEffect, useState} from 'react';

import './css/App.css';

import {Route, Routes} from "react-router-dom";
import GalleryPage from "./components/GalleryPage";
import About from "./components/About";
import ProductPage from "./components/ProductPage";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {storage} from "./firebaseConfig";
import {functions} from "./firebaseConfig";

import LoadingAnimation from "./components/LoadingAnimation";
import Footer from "./components/Footer";
import {httpsCallable} from "firebase/functions";

function App() {
    const [galleries, setGalleries] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('');
    const [isShopVisible, setIsShopVisible] = useState(false);
    const listRef = ref(storage, 'galleries/');

    // useEffect( () => {
        // async function getEnvs() {
        //     const getEnvs = httpsCallable(functions, "getEnvs");
        //     const envs = await getEnvs({});
        //     setIsShopVisible(envs.data.shopVisible);
        // }

        // getEnvs().catch(error => setError(error));


        // listAll(listRef)
        //     .then(async (res) => {
        //         const directories = await Promise.all(
        //             res.prefixes.map((item) => ({"name": item.name, "path": item.fullPath, "key": item.fullPath}))
        //         );
        //         setGalleries(directories);
        //         setIsLoaded(true);
        //     }).catch((error) => {
        //     setError(error);
        // });

    //     const galleries = [];
    //     listAll(listRef).then((snapshot) => {
    //         snapshot.items.forEach((item) => {
    //            galleries.push(item);
    //         })
    //     })
    //     setGalleries(galleries);
    //     setIsLoaded(true);
    //
    // },[listRef]);


    useEffect(() => {
        listAll(listRef)
            .then( (res) => {
                return Promise.all(
                    res.prefixes.map((item) => ({"name": item.name, "path": item.fullPath, "key": item.fullPath}))
                );
            }).then( async (res) => {
                setGalleries(res);
                setIsLoaded(true);
        }).catch((error) => {
            setError(error);
        });

    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <div>
                <LoadingAnimation/>
                <Footer/>
            </div>
        )
    } else {


        return (

            <Routes>
                <Route path="/" element={<GalleryPage isShopVisible={isShopVisible} navbar={galleries} apiURL={"/home"}  />} />
                { galleries.map(g => <Route key={g.path} path={"/" + g.name} element={<GalleryPage isShopVisible={isShopVisible} navbar={galleries} apiURL={g.path}/>}></Route>) }

                {isShopVisible === true ? <Route path="/shop" element={<ProductPage />} /> : null}

                <Route path="/about" element={<About isShopVisible={isShopVisible} navbar={galleries} />} />
            </Routes>
        );
    }
}

export default App;
