import {useEffect, useState} from "react";
import Cart from "./Cart";
import Product from "./Product";
import '../css/loading_animation.css'
import '../css/product_page.css';
import LoadingAnimation from "./LoadingAnimation";
import Footer from "./Footer";
import {httpsCallable} from "firebase/functions";
import {functions} from "../firebaseConfig";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductPage = () => {

    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);

    const getProducts = httpsCallable(functions, "getStripeProducts");

    useEffect( () => {
        const query = new URLSearchParams(window.location.search);

        getProducts()
            .then((result) => {

                setProducts(result.data);
                setIsLoaded(true);
            })
            .catch((error) => setError(error));

        if (query.get("success")) {

            toast.success("Order placed! You will receive an email confirmation.", {
                position: "top-right",
            });
        }

        if (query.get("canceled")) {

            toast.warn(
                "Order canceled -- continue to shop around and checkout when you're ready.",
                {
                    position: "top-right",
                }
            );
        }
    },[getProducts]);
    const handleRemove = (item) => {
        handleChange(item.id, -item.amount);
    }
    const handleChange= (productCode, d) => {

        setCart((cart) =>
            cart.flatMap((cartItem) =>
                cartItem.id === productCode
                    ? cartItem.amount + d < 1
                        ? [] // <-- remove item if amount will be less than 1
                        : [
                            {
                                ...cartItem,
                                amount: cartItem.amount + d
                            }
                        ]
                    : [cartItem]
            )
        );
    }

    const handleClick = (item) => {
        if (cart.some((cartItem) => cartItem.id === item.id)) {
            setCart((cart) =>
                cart.map((cartItem) =>
                    cartItem.id === item.id
                        ? {
                            ...cartItem,
                            amount: cartItem.amount + 1
                        }
                        : cartItem
                )
            );
            return;
        }

        setCart((cart) => [
            ...cart,
            { ...item, amount: 1 } // <-- initial amount 1
        ]);
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {
        return (
            <div className={"container"}>
                <ToastContainer />
                <div className={"shop-navbar"}>
                    <a href={"/"}><span  className={"name-row"}>Haág István</span></a>
                    <Cart items={cart} handleRemove={handleRemove} handleChange={handleChange}/>
                </div>
                <div className={"centered-row"}>
                    {isLoaded ?
                    <div className={"products-wrapper"}>
                        {products.map(item => (
                            <Product key={item.id} handleClick={handleClick} item={item}/>
                        ))}
                    </div>
                    :<LoadingAnimation />}
                </div>
                <Footer />
            </div>
        );
    }
}

export default ProductPage;