

function Product(props) {

    return(
        <div className={"product card"}>
            <div className={"product-image-wrapper"}>
                <img className={"product-image"} src={props.item.imageURLs[0]}  alt={props.item.name}/>
            </div>
            <div className={"product-card-details"}>
                <div className={"product-card-text"}>
                    <h1 className={"product-name"}>{props.item.name}</h1>
                    <h2 className={"product-price"}>{(props.item.price/100).toFixed(2)} {props.item.currency.toUpperCase()}</h2>
                    {/*<p className={"product-description"}>{props.item.description}</p>*/}
                </div>
                <button className={"product-btn"} onClick={() => props.handleClick(props.item)}>Add to Cart</button>
            </div>
        </div>
    )
}

export default Product;